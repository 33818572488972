import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Upload from "./components/Upload";
import Header from "./components/Header";
import ManageOffers from "./components/ManageOffers";
import Login from "./components/Auth/Login";
import Settings from "./components/Settings";

const AppContent = () => {
  const location = useLocation();
  const showHeader = location.pathname !== "/login";

  return (
    <div className="min-h-screen bg-black text-white overflow-hidden">
      <div className="bg-container-bg rounded-[3rem]">
        {showHeader && <Header />}
        <div className="p-5">
          <Routes>
            <Route path="/" element={<Upload />} />
            <Route path="/manage" element={<ManageOffers />} />
            <Route path="/login" element={<Login />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
