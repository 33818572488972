import React, { useState } from "react";
import axios from "axios";
import { BsArrowLeftCircle, BsEyeFill } from "react-icons/bs";
import { BiRefresh } from "react-icons/bi";

const ProxyImage = ({ imageUrl, altText }) => {
  const encodedUrl = encodeURIComponent(imageUrl);

  return (
    <img
      src={`https://api.hitscan.app/proxy/image?url=${encodedUrl}`}
      alt={altText}
      className="w-24 h-auto rounded-md shadow-md object-cover"
    />
  );
};

const Items = ({ title, value }) => (
  <td className="space-y-1 px-4 py-2 text-nowrap text-white ">
    <h3 className=" font-semibold text-[#606060] text-sm uppercase">{title}</h3>
    <p className="text-[#D6D7D7] ">{value}</p>
  </td>
);

const ManageOffers = () => {
  const [offers, setOffers] = useState({
    pokemon_offers: [],
    lorcana_offers: [],
  });
  const [lowestPrices, setLowestPrices] = useState({});
  const [modifiedPrices, setModifiedPrices] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchOffers = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        "https://api.hitscan.app/api/cardmarket/offers"
      );
      setOffers(response.data);
    } catch (err) {
      setError("Failed to fetch offers.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchLowestPrices = async (url, condition, language, key) => {
    try {
      const response = await axios.post(
        "https://api.hitscan.app/api/lowest-price",
        { url, condition, language }
      );
      setLowestPrices((prev) => ({
        ...prev,
        [key]: response.data.lowest_prices,
      }));
    } catch (err) {
      console.error(`Error fetching prices for ${key}:`, err);
    }
  };

  const fetchAllPrices = async () => {
    setLoading(true);
    setError(null);
    const promises = [];
    const newLowestPrices = {};

    [...offers.pokemon_offers, ...offers.lorcana_offers].forEach(
      (offer, index) => {
        const key = offer.link || `${offer.name}-${index}`;
        promises.push(
          axios
            .post("https://api.hitscan.app/api/lowest-price", {
              url: offer.link,
              condition: offer.condition_title,
              language: offer.language,
            })
            .then((response) => {
              newLowestPrices[key] = response.data.lowest_prices;
            })
            .catch((err) => {
              console.error(`Error for ${offer.name}:`, err);
            })
        );
      }
    );

    await Promise.all(promises);
    setLowestPrices(newLowestPrices);
    setLoading(false);
  };

  const handlePriceChange = (key, value) => {
    setModifiedPrices((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const renderTable = (offers, title) => (
    <div className="my-6">
      <h2 className="text-xl font-semibold mb-4 text-white">{title}</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto bg-gray-800 shadow-lg rounded-lg ">
          <tbody className="overflow-auto">
            {offers.map((offer, index) => {
              const key = offer.link || `${offer.name}-${index}`;

              return (
                <tr
                  key={index}
                  className="border-b border-app-bg bg-container-bg text-nowrap p-6 flex items-center justify-between gap-8"
                >
                  <td className="py-2 px-4 text-nowrap min-w-32 max-w-full">
                    <ProxyImage imageUrl={offer.image} altText={offer.name} />
                  </td>

                  <Items title={"Card Name"} value={offer.name} />
                  <Items title={"Set Name"} value={"SV2A"} />
                  <Items title={"Card Number"} value={"204 / 165"} />
                  <Items title={"Condition"} value={offer.condition_title} />
                  <Items title={"Language"} value={offer.language} />
                  <Items title={"Marketplace"} value={"Cardmarket"} />

                  <td className="space-y-1 px-4 py-2 text-nowrap text-white ">
                    <h3 className=" font-semibold text-[#606060] text-sm uppercase">
                      Lowest Price
                    </h3>
                    <p className="text-white ">0.05 $ </p>
                    <p className="text-white ">0.03 $ </p>
                  </td>

                  <td className="py-2 px-4 text-nowrap flex flex-col gap-4">
                    <a
                      href={offer.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-[#353434] px-4 py-2  text-white rounded-md shadow-sm text-center flex gap-2 items-center justify-center"
                    >
                      <BsEyeFill className="text-white text-lg" />
                      View Offer
                    </a>
                    {/* <button
                      onClick={() =>
                        fetchLowestPrices(
                          offer.link,
                          offer.condition_title,
                          offer.language,
                          key
                        )
                      }
                      className="px-4 py-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600"
                    >
                      Get Prices
                    </button> */}
                    <button className="px-4 py-2 bg-[#0C6FFF] text-white rounded-md shadow-sm flex gap-2 items-center justify-center">
                      <BiRefresh className="text-white text-xl  " />
                      Reload Price
                    </button>
                    <button className="px-4 py-2 bg-[#9900C2] text-white rounded-md shadow-sm flex gap-2 items-center justify-center ">
                      <BsArrowLeftCircle className="text-white text-lg" />
                      Update Offer
                    </button>
                  </td>
                  {/* <td className="py-2 px-4 text-nowrap text-white">
                    {lowestPrices[key] ? (
                      <ul className="list-disc pl-4">
                        {lowestPrices[key].map((price, i) => (
                          <li key={i}>{price} €</li>
                        ))}
                      </ul>
                    ) : (
                      "Not available"
                    )}
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className=" px-4 sm:px-6 lg:px-8 py-6 bg-app-bg rounded-3xl">
      <h1 className="text-2xl font-bold text-white mb-4">
        My Cardmarket Offers
      </h1>
      <div className="mb-6 flex gap-4">
        <button
          onClick={fetchOffers}
          disabled={loading}
          className={`px-4 py-2 rounded-md shadow-sm ${
            loading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-green-500 hover:bg-green-600 text-white"
          }`}
        >
          {loading ? "Loading..." : "Load Offers"}
        </button>
        <button
          onClick={fetchAllPrices}
          disabled={loading}
          className={`px-4 py-2 rounded-md shadow-sm ${
            loading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600 text-white"
          }`}
        >
          {loading ? "Loading..." : "Fetch All Prices"}
        </button>
      </div>
      {error && <p className="text-red-500">{error}</p>}
      {offers.pokemon_offers.length > 0 &&
        renderTable(offers.pokemon_offers, "Pokémon")}
      {offers.lorcana_offers.length > 0 &&
        renderTable(offers.lorcana_offers, "Lorcana")}
    </div>
  );
};

export default ManageOffers;
