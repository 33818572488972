import React from "react";

const Settings = () => {
  return (
    <div className="bg-app-bg min-h-screen flex items-center justify-center p-4 py-16">
      <div className="bg-container-bg p-8 rounded-xl shadow-lg w-full max-w-lg">
        <h2 className="text-3xl font-bold mb-6 text-center">Settings</h2>
        <form className="space-y-6">
          {/* Card Market ID */}
          <div>
            <label
              className="block text-lg font-medium mb-2"
              htmlFor="cardMarketId"
            >
              Card Market ID
            </label>
            <input
              id="cardMarketId"
              type="text"
              placeholder="Enter your Card Market ID"
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring focus:ring-blue-200 focus:outline-none bg-container-bg"
            />
          </div>

          {/* Card Market Password */}
          <div>
            <label
              className="block text-lg font-medium mb-2"
              htmlFor="cardMarketPassword"
            >
              Card Market Password
            </label>
            <input
              id="cardMarketPassword"
              type="password"
              placeholder="Enter your Card Market Password"
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring focus:ring-blue-200 focus:outline-none bg-container-bg"
            />
          </div>

          {/* eBay API Key */}
          <div>
            <label className="block text-lg font-medium mb-2" htmlFor="ebayApi">
              eBay API Key
            </label>
            <input
              id="ebayApi"
              type="text"
              placeholder="Enter your eBay API Key"
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring focus:ring-blue-200 focus:outline-none bg-container-bg"
            />
          </div>

          {/* CSV Format */}
          <div>
            <label
              className="block text-lg font-medium mb-2"
              htmlFor="csvFormat"
            >
              CSV Format
            </label>
            <input
              id="csvFormat"
              type="text"
              placeholder="Enter CSV format"
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring focus:ring-blue-200 focus:outline-none bg-container-bg"
            />
          </div>

          {/* Settings Price % */}
          <div>
            <label
              className="block text-lg font-medium mb-2"
              htmlFor="pricePercentage"
            >
              Settings Price %
            </label>
            <input
              id="pricePercentage"
              type="number"
              placeholder="Enter price percentage"
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring focus:ring-blue-200 focus:outline-none bg-container-bg"
            />
          </div>

          {/* Save Button */}
          <div className="text-center">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
            >
              Save Settings
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Settings;
