import React from "react";

const Login = () => {
  // This component will be used to render the login form

  return (
    <div className="   ">
      <div className="flex justify-center items-center h-screen ">
        <form className="bg-app-bg p-10 rounded-lg shadow-lg w-2/5 max-w-[95%]  ">
          <h2 className="text-3xl  font-bold mb-4">Login</h2>
          <div className="mb-5">
            <label className="block  text-lg  mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline bg-container-bg"
              id="email"
              type="email"
              placeholder="example@gmail.com"
            />
          </div>
          <div className="mb-6">
            <label className="block  text-lg  mb-2" htmlFor="password">
              Password
            </label>
            <input
              className="shadow appearance-none border border-red rounded w-full py-2 px-3  mb-3 leading-tight focus:outline-none focus:shadow-outline bg-container-bg"
              id="password"
              type="password"
              placeholder="******************"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              Sign In
            </button>
            <a
              className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
              href="#"
            >
              Forgot Password?
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
