import React from "react";

const ResponsiveContainer = ({ children }) => {
  return (
    <div className="bg-app-bg min-h-screen p-6 rounded-xl flex flex-col xl:flex-row gap-4 justify-between">
      {children}
    </div>
  );
};

export default ResponsiveContainer;
