import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FiPackage as PackageIcon,
  FiMousePointer as CursorIcon,
  FiBarChart2 as GraphIcon,
  FiBookmark as BookmarkIcon,
  FiSettings as SettingsIcon,
} from "react-icons/fi";

const baseButtonClass =
  "flex items-center gap-2 px-3 py-2 rounded-lg text-sm md:text-base w-full xl:w-auto";
const baseLinkClass =
  "flex items-center gap-2 hover:text-gray-400 text-sm md:text-base text-white w-full xl:w-auto";

const navItems = [
  {
    label: "Upload or Scan a product",
    icon: PackageIcon,
    type: "link",
    to: "/",
  },
  {
    label: "Manage my offers",
    icon: CursorIcon,
    type: "link",
    to: "/manage",
  },
  {
    label: "Cardmarket",
    icon: GraphIcon,
    type: "link",
    to: "/cardmarket",
  },
  {
    label: "My collection",
    icon: BookmarkIcon,
    type: "link",
    to: "/collection",
  },
  {
    label: "Settings",
    icon: SettingsIcon,
    type: "link",
    to: "/settings",
  },
];

const NavItem = ({ item, navigate, isActive }) => {
  const Icon = item.icon;
  const className = isActive
    ? `${baseButtonClass} bg-container-bg py-3 rounded-2xl text-white hover:bg-gray-600 `
    : baseLinkClass;

  return (
    <button className={className} onClick={() => navigate(item.to)}>
      <Icon className="text-lg" />
      <span className="text-sm md:text-base">{item.label}</span>
    </button>
  );
};

const NavItems = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex w-full flex-col xl:flex-row flex-wrap gap-4 md:gap-6 justify-center items-center bg-app-bg p-4 rounded-xl">
      {navItems.map((item, index) => (
        <NavItem
          key={index}
          item={item}
          navigate={navigate}
          isActive={location.pathname === item.to}
        />
      ))}
    </div>
  );
};

export default NavItems;
